/**
 * Copyright 2007-2023 Waynet Sp. z o.o.
 *
 * NOTICE OF LICENSE
 *
 * This file is not open source! Each license that you purchased is only available for 1 website only.
 * If you want to use this file on more websites (or projects), you need to purchase additional licenses.
 * You are not allowed to redistribute, resell, lease, license, sub-license or offer our resources to any third party.
 *
 * DISCLAIMER
 *
 * Do not edit or add to this file if you wish to upgrade PrestaShop to newer
 * versions in the future. If you wish to customize PrestaShop for your
 * needs please contact us for extra customization service at an affordable price
 *
 *  @author Waynet <kontakt@waynet.pl>
 *  @copyright  2014-2023 Waynet
 *  @license    Valid for 1 website (or project) for each purchase of license
 */

/* global $, prestashop, massAddToCartSmartyCacheEnabled, massAddToCartGetQuantitiesUrl */
$(document).ready(() => {
    const $successModal = $('#wnet_massaddtocart_success_modal');
    const $successModalBody = $successModal.find('.js-wnet-massaddtocart-success-modal-body');
    const inputSelector = '.js-wnet-massaddtocart-qty-input';
    const inputMiniatureSelector = '.js-wnet-massaddtocart-miniature-qty-input';
    const $body = $('body');

    initSpinners(inputSelector);
    initSpinners(inputMiniatureSelector);

    if (massAddToCartSmartyCacheEnabled) {
        $('.js-wnet-massaddtocart-token-input').val(prestashop.static_token);
        updateSimpleProductsInputs();
    }

    $(document).on('click', '.js-wnet-massaddtocart-open-product-modal', (e) => {
        e.preventDefault();

        const $button = $(e.currentTarget);
        const modalSelector = $button.data('target');
        const ajaxUrl = $button.data('get-modal-url');
        $button.attr('disabled', true);

        getAddToCartModal(ajaxUrl, modalSelector).then(() => {
            $button.attr('disabled', false);
            initSpinners(inputSelector);
        });
    });

    function getAddToCartModal(ajaxUrl, modalSelector) {
        return $.ajax(ajaxUrl, {
            success: (modal) => {
                $body.append(modal);
                const $modal = $(modalSelector);
                $modal.modal('show');
                handleModalClose($modal);
            },
            error: handleError
        });
    }

    function handleModalClose($modal) {
        const handleClose = () => {
            $modal.off('hidden.bs.modal', handleClose);
            $modal.remove();
        };

        $modal.on('hidden.bs.modal', handleClose);
    }

    function updateQuantityInputs($wrapper, availableQuantities, resetValue = false) {
        for (const combinationId in availableQuantities) {
            const $input = $wrapper.find(`#wnet_massaddtocart_${combinationId}_qty`);
            $input.attr('max', availableQuantities[combinationId]);
            !resetValue || $input.val(0);
        }
    }

    $(document).on('submit', '.js-wnet-massaddtocart-add-to-cart-form', (e) => {
        e.preventDefault();

        const $form = $(e.currentTarget);
        const $modal = $form.closest('.js-wnet-massaddtocart-product-modal');

        $.ajax({
            url: $form.attr('action'),
            method: 'POST',
            data: $form.serialize(),
            success: (response) => {
                updateQuantityInputs($form, response.quantities, true);
                $('.js-wnet-massadd-summary').html('');

                prestashop.emit('updateCart', {
                    resp: {...response, hasError: false},
                    reason: {
                        cart: response.cart,
                        linkAction: 'mass-add-to-cart',
                    },
                });

                if (0 === $modal.length) {
                    $successModalBody.html(response.modal_content);
                    $successModal.modal('show');

                    return;
                }

                $modal.modal('hide');

                const showModal = () => {
                    $successModalBody.html(response.modal_content);
                    $successModal.modal('show');
                    $modal.off('hidden.bs.modal', showModal);
                }

                $modal.on('hidden.bs.modal', showModal);
            },
            error: (jqXHR, textStatus, error) => {
                if ('responseJSON' in jqXHR && 'quantities' in jqXHR.responseJSON) {
                    updateQuantityInputs($form, jqXHR.responseJSON.quantities);
                }

                handleError(jqXHR, textStatus, error)
            }
        });
    });

    prestashop.on('updateCart', (event) => {
        if (!event || !event.resp) {
            return;
        }

        if (event.resp.hasError) {
            updateSimpleProductsInputs(true);
        } else if (event.reason && 'delete-from-cart' === event.reason.linkAction) {
            if (0 !== parseInt(event.reason.idProductAttribute) || 0 < event.reason.idCustomization) {
                return;
            }

            updateSimpleProductsInputs(false, event.reason.idProduct);
        } else if (event.resp.cart && event.resp.cart.products) {
            event.resp.cart.products.forEach(product => {
                if (0 !== parseInt(product.id_product_attribute) || 0 < product.id_customization) {
                    return;
                }

                updateSimpleProductInputs(product.id_product, product.stock_quantity - product.quantity);
            });
        }
    });

    function updateSimpleProductsInputs(refreshCart = false, productId = null) {
        let inputSelector = `${inputMiniatureSelector}[max]`;
        if (null !== productId) {
           inputSelector += `[data-id-product="${productId}"]`;
        }

        const $inputs = $(inputSelector);
        if (0 === $inputs.length) {
            return;
        }

        const url = new URL(massAddToCartGetQuantitiesUrl);

        $inputs.each((index, element) => {
            url.searchParams.append('id_product[]', $(element).data('id-product'));
        });

        if (refreshCart) {
            url.searchParams.set('refresh_cart', '1');
        }

        $.ajax(url.toString(), {
            success: (response) => {
                if (refreshCart) {
                    prestashop.cart = response.cart;
                }

                for (const productId in response.quantities) {
                    updateSimpleProductInputs(productId, response.quantities[productId]);
                }
            },
            error: handleError
        });
    }

    function updateSimpleProductInputs(productId, availableQuantity) {
        const $inputs = $(`${inputMiniatureSelector}[data-id-product="${productId}"]`);

        $inputs.each((index, element) => {
            const $input = $(element);
            $input.val(0);

            if ('undefined' === typeof $input.attr('max')) {
                return;
            }

            let minQuantity = $input.attr('min');
            const $submitButton = $input.closest('form').find(':submit');

            if (minQuantity > availableQuantity) {
                availableQuantity = minQuantity = 0;
                $submitButton.prop('disabled', true).addClass('disabled');
            } else {
                $submitButton.prop('disabled', false).removeClass('disabled');
            }

            $input.attr('max', availableQuantity);
            $input.trigger('touchspin.updatesettings', {
                min: minQuantity,
                max: availableQuantity
            });
        });
    }

    let qtyRequestTimeout = null;
    const timeout = 300;

    $(document).on('change', inputSelector, (e) => {
        const $input = $(e.currentTarget);
        const $wrapper = $input.closest('.js-wnet-massaddtocart-combinations-wrapper');

        const combinationId = parseInt($input.data('id-combination'));
        const value = parseInt($input.val());
        const minValue = parseInt($input.data('min'));

        if (qtyRequestTimeout !== null) {
            clearTimeout(qtyRequestTimeout);
        }

        qtyRequestTimeout = setTimeout(() => updateModalSummary($wrapper), timeout)

        if (value >= minValue || isInCart(combinationId)) {
            $input.data('previous-value', value);

            return;
        }

        const previousValue = parseInt($input.attr('data-previous-value'));
        const newValue = previousValue < value ? minValue : 0;

        $input.val(newValue);
        $input.data('previous-value', newValue);
    });

    function getProductsQuantities($wrapper) {
        const quantities = {};

        $wrapper.find(inputSelector).each((i, input) => {
            const $input = $(input);
            const combinationId = parseInt($input.data('id-combination'));
            const quantity = parseInt($input.val());

            if (quantity > 0) {
                quantities[combinationId] = quantity;
            }
        });

        return quantities;
    }

    function updateModalSummary($wrapper) {
        const $summary = $('.js-wnet-massadd-summary');
        const url = $wrapper.data('summary-url');
        const quantities = getProductsQuantities($wrapper);

        if ($.isEmptyObject(quantities)) {
            $summary.html('');

            return;
        }

        $.ajax({
            url: url,
            data: {
                quantities: quantities,
            },
            success: (response) => {
                $summary.replaceWith(response.summary);
            },
        });
    }

    function initSpinners(inputSelector) {
        $(inputSelector).each((i, input) => {
            const $input = $(input);
            const min = parseInt(($input.attr('min') ?? 0), 10);
            const max = parseInt(($input.attr('max') ?? 10000), 10);

            $input.TouchSpin({
                verticalbuttons: true,
                verticalupclass: 'material-icons touchspin-up',
                verticaldownclass: 'material-icons touchspin-down',
                buttondown_class: 'btn btn-touchspin js-touchspin',
                buttonup_class: 'btn btn-touchspin js-touchspin',
                min: min,
                max: max,
            });
        });
    }

    function handleError(jqXHR, textStatus, error) {
        if ('responseJSON' in jqXHR && 'message' in jqXHR.responseJSON) {
            alert(jqXHR.responseJSON.message);
        } else {
            console.log(textStatus, error);
        }
    }

    function isInCart(combinationId) {
        return prestashop.cart.products.some(product => combinationId === parseInt(product.id_product_attribute));
    }
});
